import { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../services/firebase.config';
import { doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuthData = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [userData, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null); // State for user role

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const docRef = doc(db, 'AYS Lite Users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log("donSnat Exists");
          
          const userData = { id:user.uid, ...docSnap.data()} ;
          
          // const userData = docSnap.data();
          setRole(userData.role); // Save user role
          setUser(userData);
          console.log(userData);
          
        } else {
          console.error('No such document!');
        }
      } else {
        setUser(null);
        setRole(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ userData, role, loading }}>
      {children}
    </AuthContext.Provider>
  );
};


