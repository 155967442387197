import React from 'react';
import { useAreaData } from '../../hooks/useAreaData';
import { useCityData } from '../../hooks/useCityData';

const AreaList = ({ onEditArea }) => {
  const { areas, isLoading, isError, deleteArea } = useAreaData();
  const { cities } = useCityData();

  if (isLoading) return <p>Loading areas...</p>;
  if (isError) return <p>Error loading areas</p>;

  const getCityName = (cityId) => {
    const city = cities.find((city) => city.id === cityId);
    return city ? city.name : 'Unknown City';
  };

  const handleDelete = (id) => {
    deleteArea(id);
  };

  return (
    <table className="min-w-full table-auto">
      <thead className="bg-gray-800">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Area Name</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">City</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Active</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Actions</th>
        </tr>
      </thead>
      <tbody>
        {areas.map((area) => (
          <tr key={area.id} className="hover:bg-gray-700">
            <td className="border px-4 py-2">{area.name}</td>
            <td className="px-6 py-4">{getCityName(area.cityId)}</td>
            <td className="px-6 py-4">{area.active ? 'Yes' : 'No'}</td>
            <td className="border px-4 py-2">
              <button
                onClick={() => onEditArea(area)}
                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300"
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(area.id)}
                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300 ml-2"
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AreaList;
