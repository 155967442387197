import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainSpinner from '../components/MainSpinner';
import { useQuery, useMutation } from 'react-query';
import { collection, getDocs, query, where, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../services/firebase.config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { toast } from 'react-toastify';

// import React, { useState } from 'react';
// import { useQuery } from 'react-query';
// import { collection, getDocs, query, where, doc, getDoc, updateDoc } from 'firebase/firestore';
// import { db, storage } from '../services/firebase.config';
// import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// import { toast } from 'react-toastify';
// import MainSpinner from '../components/MainSpinner';

const fetchConfirmedBookings = async () => {
  const bookingsCollection = collection(db, "AYS Lite Bookings");
  const q = query(bookingsCollection, where("isConfirmed", "==", true));
  const snapshot = await getDocs(q);

  const bookings = await Promise.all(
    snapshot.docs.map(async (bookingDoc) => {
      const bookingData = bookingDoc.data();
      const customerRef = doc(db, 'AYS Lite Users', bookingData.customerId);
      const customerSnap = await getDoc(customerRef);
      const customerData = customerSnap.exists() ? customerSnap.data() : {};

      return {
        ...bookingData,
        bookingId: bookingDoc.id,
        customerName: customerData.name || 'Unknown',
        customerArea: customerData.area || 'Unknown',
        customerPhone: customerData.phone || 'N/A',
      };
    })
  );

  return bookings;
};

const CustomerBookings = () => {
  const { data: bookings, isLoading, error } = useQuery(
    ['AYS Lite ConfirmedBookings'],
    fetchConfirmedBookings
  );

  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isEndBookingOpen, setIsEndBookingOpen] = useState(false);
  const [isCancelBookingOpen, setIsCancelBookingOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [reviewImage, setReviewImage] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("Airtel Money");
  const [amountPaid, setAmountPaid] = useState("");
  const [cancelReason, setCancelReason] = useState("");

  const handleEndBooking = async () => {
    try {
      let imageUrl = null;
      if (reviewImage) {
        const storageRef = ref(storage, `bookings/${selectedBooking.bookingId}/review-image/${reviewImage.name}`);
        const snapshot = await uploadBytes(storageRef, reviewImage);
        imageUrl = await getDownloadURL(snapshot.ref);
      }

      const bookingRef = doc(db, "AYS Lite Bookings", selectedBooking.bookingId);
      const reviewData = {
        rating,
        review,
        imageUrl,
        serviceId: selectedBooking.serviceId,
        packageId: selectedBooking.packageName,
        createdAt: new Date()
      };

      await updateDoc(bookingRef, {
        status: "Completed",
        review: reviewData,
        paymentMethod,
        amountPaid,
        balanceDue: selectedBooking.fullPaymentAmount - amountPaid,
      });

      toast.success("Booking completed with review!");
      setIsEndBookingOpen(false);
    } catch (error) {
      console.error("Error ending booking:", error);
      toast.error("Error completing booking. Please try again.");
    }
  };

  const handleCancelBooking = async () => {
    try {
      const bookingRef = doc(db, "AYS Lite Bookings", selectedBooking.bookingId);
      await updateDoc(bookingRef, {
        status: "Cancelled",
        cancelReason,
      });
      toast.success("Booking cancelled successfully.");
      setIsCancelBookingOpen(false);
    } catch (error) {
      console.error("Error cancelling booking:", error);
      toast.error("Error cancelling booking. Please try again.");
    }
  };

  if (isLoading) return <MainSpinner />;
  if (error) return <p>Something went wrong: {error.message}</p>;

  return (
    <div className="customer-bookings-container p-4">
      <h2 className="text-xl font-bold mb-4">My Bookings</h2>

      {/* End Booking Form */}
      {isEndBookingOpen && (
        <div className="modal-overlay">
          <div className="modal bg-gray-900 text-yellow-500 p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-green-500 t mb-4">Complete Booking & Leave a Review</h3>
            <label className="block mt-2">Payment Method</label>
            <select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              className="bg-gray-700 p-2 rounded text-white w-full"
            >
              <option value="Airtel Money">Airtel Money</option>
              <option value="MoMo">MoMo</option>
              <option value="Bank Transfer">Bank Transfer</option>
            </select>
            <label className="block mt-2">Amount Paid</label>
            <input
              type="number"
              value={amountPaid}
              onChange={(e) => setAmountPaid(e.target.value)}
              className="bg-gray-700 p-2 rounded text-white w-full"
            />
            <label className='block'>Rate Service: 1-5</label>
            <input
              type="number"
              value={rating}
              onChange={(e) => setRating(e.target.value)}
              min="1"
              max="5"
              className="bg-gray-700 p-2 m-2 rounded text-white"
            />
            <textarea
              value={review}
              onChange={(e) => setReview(e.target.value)}
              placeholder="Leave a review (Optinal)"
              className="bg-gray-700 p-2 mt-2 rounded text-white w-full"
            />
            <label className="block  mt-2">Upload an image (optional):</label>
            <input type="file" onChange={(e) => setReviewImage(e.target.files[0])} className="bg-gray-700 p-2 rounded text-white" />
            
            <button onClick={handleEndBooking} className="bg-green-500 text-white px-4 py-2 rounded mt-2">Confirm Completion</button>
            <button onClick={() => setIsEndBookingOpen(false)} className="bg-gray-500 text-white px-4 py-2 rounded ml-4 mt-2">Close</button>
          </div>
        </div>
      )}

      {/* Cancel Booking Form */}
      {isCancelBookingOpen && (
        <div className="modal-overlay">
          <div className="modal bg-gray-900 text-white p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold mb-4">Cancel Booking</h3>
            <textarea
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
              placeholder="Reason for cancellation (optional)"
              className="bg-gray-700 p-2 mt-2 rounded text-white w-full"
            />
            <button onClick={handleCancelBooking} className="bg-red-500 text-white px-4 py-2 rounded mt-2">Confirm Cancellation</button>
            <button onClick={() => setIsCancelBookingOpen(false)} className="bg-gray-500 text-white px-4 py-2 rounded ml-4 mt-2">Close</button>
          </div>
        </div>
      )}

      <ul className="booking-list">
        {bookings.map((booking) => (
          <li
            key={booking.bookingId}
            className="booking-item bg-gray-800 p-4 mb-4 rounded-lg shadow-lg"
          >
            <h3 className="text-lg font-semibold text-green-400">
            <strong>Service:</strong> {booking.packageName}
            </h3>
            <p><strong>Deposit Amnt:</strong> K{booking.depositAmount}</p>
            <p><strong>Service Amount:</strong> K{booking.fullPaymentAmount}</p>
            <p><strong>Status:</strong> {booking.status}</p>
            <p><strong>Service Date:</strong> {booking.date} at {booking.time}</p>

            <button
              onClick={() => {
                setSelectedBooking(booking);
                setIsEndBookingOpen(true);
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
            >
              End Booking
            </button>
            <button
              onClick={() => {
                setSelectedBooking(booking);
                setIsCancelBookingOpen(true);
              }}
              className="bg-red-500 text-white px-4 py-2 rounded mt-2 ml-2"
            >
              Cancel Booking
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomerBookings;

// const fetchConfirmedBookings = async () => {
//   const bookingsCollection = collection(db, "AYS Lite Bookings");
//   const q = query(bookingsCollection, where("isConfirmed", "==", true));
//   const snapshot = await getDocs(q);

//   const bookings = await Promise.all(
//     snapshot.docs.map(async (bookingDoc) => {
//       const bookingData = bookingDoc.data();
//       const customerRef = doc(db, 'AYS Lite Users', bookingData.customerId);
//       const customerSnap = await getDoc(customerRef);
//       const customerData = customerSnap.exists() ? customerSnap.data() : {};

//       return {
//         ...bookingData,
//         bookingId: bookingDoc.id,
//         customerName: customerData.name || 'Unknown',
//         customerArea: customerData.area || 'Unknown',
//         customerPhone: customerData.phone || 'N/A',
//       };
//     })
//   );

//   return bookings;
// };

// const CustomerBookings = () => {
//   const { data: bookings, isLoading, error } = useQuery(
//     ['AYS Lite ConfirmedBookings'],
//     fetchConfirmedBookings
//   );
//   const navigate = useNavigate();
//   const [selectedBooking, setSelectedBooking] = useState(null);
//   const [rating, setRating] = useState(0);
//   const [review, setReview] = useState("");
//   const [reviewImage, setReviewImage] = useState(null);
//   const [editPhone, setEditPhone] = useState("");
//   const [editNote, setEditNote] = useState("");

//   const handleEndBooking = async (booking) => {
//     try {
//       let imageUrl = null;
//       if (reviewImage) {
//         const storageRef = ref(storage, `bookings/${booking.bookingId}/review-image/${reviewImage.name}`);
//         const snapshot = await uploadBytes(storageRef, reviewImage);
//         imageUrl = await getDownloadURL(snapshot.ref);
//       }

//       const bookingRef = doc(db, "AYS Lite Bookings", booking.bookingId);
//       await updateDoc(bookingRef, {
//         status: "Completed",
//         rating,
//         review,
//         reviewImage: imageUrl,
//       });
//       toast.success("Booking completed successfully with review!");
//     } catch (error) {
//       console.error("Error ending booking:", error);
//       toast.error("Error ending booking. Please try again.");
//     }
//   };

//   const handleCancelBooking = async (booking) => {
//     const bookingRef = doc(db, "AYS Lite Bookings", booking.bookingId);
//     try {
//       await updateDoc(bookingRef, {
//         status: "Cancelled",
//         cancelReason: "Customer requested cancellation",
//       });
//       toast.success("Booking cancelled successfully.");
//     } catch (error) {
//       console.error("Error cancelling booking:", error);
//       toast.error("Error cancelling booking. Please try again.");
//     }
//   };

//   const handleEditDetails = async (booking) => {
//     const bookingRef = doc(db, "AYS Lite Bookings", booking.bookingId);
//     try {
//       await updateDoc(bookingRef, {
//         customerPhone: editPhone,
//         customerNote: editNote,
//       });
//       toast.success("Details updated successfully.");
//     } catch (error) {
//       console.error("Error updating details:", error);
//       toast.error("Error updating details. Please try again.");
//     }
//   };

//   const handleFileChange = (e) => setReviewImage(e.target.files[0]);

//   if (isLoading) return <MainSpinner />;
//   if (error) return <p>Something went wrong: {error.message}</p>;

//   return (
//     <div className="admin-bookings-container p-4">
//       <h2 className="text-xl font-bold mb-4">My Bookings</h2>
//       <ul className="booking-list">
//         {bookings.map((booking) => (
//           <li
//             key={booking.bookingId}
//             className="booking-item bg-gray-800 p-4 mb-4 rounded-lg shadow-lg"
//           >
//             <h3 className="text-lg font-semibold text-green-400">
//               {booking.serviceName} - {booking.packageName}
//             </h3>
//             <p><strong>Customer:</strong> {booking.customerName} - {booking.customerPhone}</p>
//             <p><strong>Area:</strong> {booking.customerArea}</p>
//             <p><strong>Status:</strong> {booking.status}</p>
//             <p><strong>Service Date:</strong> {booking.date} at {booking.time}</p>
//             <p><strong>Employee Assigned:</strong> {booking.assignedEmployeeName || "Not assigned"}</p>
//             <p><strong>Deposit Amount:</strong> K{booking.depositAmount} ZMW</p>
//             <p><strong>Total Payment:</strong> K{booking.fullPaymentAmount} ZMW</p>

//             {/* Edit Phone & Note */}
//             <div>
              
//               <textarea
//                 value={editNote || booking.customerNote}
//                 onChange={(e) => setEditNote(e.target.value)}
//                 placeholder="Edit your message"
//                 className="bg-gray-700 p-2 mt-2 rounded text-white w-full"
//               />
//               <button
//                 onClick={() => handleEditDetails(booking)}
//                 className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
//               >
//                 Save Changes
//               </button>
//             </div>

//             {/* End Booking (Review & Rating) */}
//             <div className="mt-4">
//               <label>Rate Service:</label>
//               <input
//                 type="number"
//                 value={rating}
//                 onChange={(e) => setRating(e.target.value)}
//                 min="1"
//                 max="5"
//                 className="bg-gray-700 p-2 rounded text-white"
//               />
//               <textarea
//                 value={review}
//                 onChange={(e) => setReview(e.target.value)}
//                 placeholder="Leave a review"
//                 className="bg-gray-700 p-2 mt-2 rounded text-white w-full"
//               />
//               <label className="block text-white mt-2">Upload an image (optional):</label>
//               <input type="file" onChange={handleFileChange} className="bg-gray-700 p-2 rounded text-white" />
//               <button
//                 onClick={() => handleEndBooking(booking)}
//                 className="bg-green-500 text-white px-4 py-2 rounded mt-2"
//               >
//                 End Booking
//               </button>
//             </div>

//             {/* Cancel Booking */}
//             <button
//               onClick={() => handleCancelBooking(booking)}
//               className="bg-red-500 text-white px-4 py-2 rounded mt-4"
//             >
//               Cancel Booking
//             </button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default CustomerBookings;



















// import React from 'react';
// import { useQuery } from 'react-query';
// import { collection, getDocs, query, where} from 'firebase/firestore';
// import { db } from '../services/firebase.config';
// import MainSpinner from '../components/MainSpinner';
// import { useAuthData } from '../context/AuthContext';

// const fetchConfirmedBookings = async (customerId) => {
//   const bookingsCollection = collection(db, 'AYS Lite Bookings');
//   const q = query(
//     bookingsCollection, 
//     where('isConfirmed', '==', true), 
//     where('customerId', '==', customerId)
//   );
//   const snapshot = await getDocs(q);
//   return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
// };

// const CustomerBookings = () => {
//   const { userData } = useAuthData();
//   const customerId = userData?.id;

//   const { data: bookings, isLoading, error } = useQuery(
//     ['AYS Lite ConfirmedBookings', customerId],
//     () => fetchConfirmedBookings(customerId),
//     {
//       enabled: !!customerId, // Only run the query if customerId is available
//     }
//   );

//   if (isLoading) return <MainSpinner />;
//   if (error) return <p>Error: {error.message}</p>;

//   return (
//     <div className="bg-gray-900 min-h-screen p-4 text-white">
//       <h2 className="text-3xl font-semibold mb-6">My Confirmed Bookings</h2>
//       <ul>
//         {bookings.length === 0 ? (
//           <p>No confirmed bookings yet</p>
//         ) : (
//           bookings.map((booking) => (
//             <li key={booking.id} className="bg-gray-800 p-4 rounded mb-4">
//               <p><strong>Service:</strong> {booking.packageName}</p>
//               <p><strong>Date:</strong> {booking.date}</p>
//               <p><strong>Time:</strong> {booking.time}</p>
//             </li>
//           ))
//         )}
//       </ul>
//     </div>
//   );
// };

// export default CustomerBookings;
