import React, { useState } from 'react';
// import CategoryForm from '../components/Categories/CategoryForm';
// import CategoryList from '../components/Categories/CategoryList';
import { useCategoryData } from '../../hooks/userCategoryData';
import CategoryForm from '../../components/Categories/CategoryForm';
import CategoryList from '../../components/Categories/CategoryList';
import MainSpinner from '../../components/MainSpinner';

const Categories = () => {
  const {  categories, isLoading, isError } = useCategoryData(); // Set default to an empty array
  const [showForm, setShowForm] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);

  // Function to handle closing the form
  const handleFormClose = () => {
    setShowForm(false);
    setCategoryToEdit(null);  // Reset category being edited
  };

  // Function to open the form for creating a new category
  const handleCreateCategory = () => {
    setCategoryToEdit(null);  // No category being edited
    setShowForm(true);  // Show form for creating a new category
  };

  // Function to open the form for editing an existing category
  const handleEditCategory = (category) => {
    setCategoryToEdit(category);  // Set the category to be edited
    setShowForm(true);  // Show form for editing
  };

  if (isLoading) return <MainSpinner/>;
  if (isError) return <p>Failed to load categories</p>;

  return (
    <div>
      <h2 className="text-3xl font-bold mb-4">Categories</h2>

      {/* Create Category Button */}
      <button
        onClick={handleCreateCategory}
        className="bg-orange-500 text-white px-4 py-2 rounded-lg mb-6 hover:bg-orange-600 transition duration-300"
      >
        Create Category
      </button>

      {/* Render the CategoryForm if showForm is true */}
      {showForm && (
        <CategoryForm
          categoryData={categoryToEdit}
          allCategories={categories}  // Pass categories that are not subcategories
        //   allCategories={categories.filter((cat) => !cat.isSubcategory)}  // Pass categories that are not subcategories
          onClose={handleFormClose}  // Pass the onClose function
        />
      )}

      {/* Pass categories and edit handler to CategoryList */}
      <CategoryList categories={categories} onEditCategory={handleEditCategory} />
    </div>
  );
};

export default Categories;
