import React, { useState } from 'react';
import AreaForm from '../../components/Locations/AreaForm';
import CityForm from '../../components/Locations/CityForm';
import CityList from '../../components/Locations/CityList';
import AreaList from '../../components/Locations/AreaList';

const Locations = () => {
  const [showCityForm, setShowCityForm] = useState(false);
  const [cityToEdit, setCityToEdit] = useState(null);
  const [showAreaForm, setShowAreaForm] = useState(false);
  const [areaToEdit, setAreaToEdit] = useState(null);

  // City handlers
  const handleCityFormClose = () => {
    setShowCityForm(false);
    setCityToEdit(null);
  };

  const handleCreateCity = () => {
    setCityToEdit(null);
    setShowCityForm(true);
  };

  const handleEditCity = (city) => {
    setCityToEdit(city);
    setShowCityForm(true);
  };

  // Area handlers
  const handleAreaFormClose = () => {
    setShowAreaForm(false);
    setAreaToEdit(null);
  };

  const handleCreateArea = () => {
    setAreaToEdit(null);
    setShowAreaForm(true);
  };

  const handleEditArea = (area) => {
    setAreaToEdit(area);
    setShowAreaForm(true);
  };

  return (
    <div>
      <h2 className="text-3xl font-bold mb-4">Locations</h2>

      {/* Cities Section */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-2xl font-semibold">Cities</h3>
          <button
            onClick={handleCreateCity}
            className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition duration-300"
          >
            Create City
          </button>
        </div>
        {showCityForm && (
          <CityForm cityData={cityToEdit} onClose={handleCityFormClose} />
        )}
        <CityList onEditCity={handleEditCity} />
      </div>

      {/* Areas Section */}
      <div>
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-2xl font-semibold">Areas</h3>
          <button
            onClick={handleCreateArea}
            className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition duration-300"
          >
            Create Area
          </button>
        </div>
        {showAreaForm && (
          <AreaForm areaData={areaToEdit} onClose={handleAreaFormClose} />
        )}
        <AreaList onEditArea={handleEditArea} />
      </div>
    </div>
  );
};

export default Locations;
