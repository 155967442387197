
import React from 'react';
import { Outlet } from 'react-router-dom';
import NavigationTabs from './NavigationTabs';
import { useMediaQuery } from 'react-responsive';

const FrontendMainLayout = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  if(isMobile){
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Main content where routes like Home, ServiceDetails will render */}
      <div className="content-container">
        <Outlet /> {/* Child routes (Home, ServiceDetails, etc.) */}
      </div>

      {/* Mobile/Larger device navigation */}
      <NavigationTabs />
    </div>
  );
}
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Main content where routes like Home, ServiceDetails will render */}
     
      {/* Mobile/Larger device navigation */}
      <NavigationTabs />
      <div className="content-container">
        <Outlet /> {/* Child routes (Home, ServiceDetails, etc.) */}
      </div>

    </div>
  );
  
};

export default FrontendMainLayout;

