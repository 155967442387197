import React, { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAuthData } from "../context/AuthContext";
import { useBookingData } from "../hooks/userBookings";
import MainSpinner from "../components/MainSpinner";
import { useServiceData } from "../hooks/useServices";
import { usePromoCodeCrud } from "../hooks/usePromoCodeCrud";

const BookNow = () => {
  const { id, packageName } = useParams();
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [customerNote, setCustomerNote] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0); // Holds the discount applied
  const [isDepositAgreed, setIsDepositAgreed] = useState(false);
  const { createBooking } = useBookingData();
  const { userData, loading: loadingUser } = useAuthData();
  const { data: services , isLoading } = useServiceData();
  const { validatePromoCode } = usePromoCodeCrud(); // For promo code validation
  const navigate = useNavigate();

  if (isLoading) return <MainSpinner />;

const service = services?.find((service) => service.id === id);
const selectedPackage = service?.packages.find((pkg) => pkg.name === packageName);

if (!service) {
  console.error(`Service with ID ${id} not found`);
  return <p>Service not found</p>;
}

if (!selectedPackage) {
  console.error(`Package with name ${packageName} not found in service ${id}`);
  return <p>Package not found</p>;
}
  // const service = services?.find((service) => service.id === id);
  // const selectedPackage = service?.packages.find((pkg) => pkg.name === packageName);

  // if (!service || !selectedPackage) return <p>Service or Package not found</p>;

  // Base amounts
  const deposit = selectedPackage.depositType === "amount"
    ? selectedPackage.depositValue
    : (selectedPackage.price * selectedPackage.depositValue) / 100;
  const fullPaymentAmount = selectedPackage.price;
  const commission = service.commissionRate
    ? (selectedPackage.price * service.commissionRate) / 100
    : 0;
  
  // Calculate the total after applying the discount
  const discountedTotal = fullPaymentAmount - discountAmount;

  if (loadingUser) return <MainSpinner />;

  // Handle applying the promo code
  const handlePromoCodeApply = async () => {
    const response = await validatePromoCode(promoCode, service.id);
    if (response.isValid) {
      setDiscountAmount((fullPaymentAmount * response.discount) / 100);
    } else {
      alert("Invalid promo code or usage limit exceeded.");
      setDiscountAmount(0);
    }
  };

  const handleBookingSubmit = (e) => {
    e.preventDefault();
    const bookingData = {
      serviceId: id,
      packageName,
      customerId: userData.id,
      date,
      time,
      customerNote,
      isDepositPaid: false,
      isFullPaymentPaid: false,
      isConfirmed: false,
      depositAmount: deposit,
      fullPaymentAmount: discountedTotal, // Use discounted total for final payment
      commissionAmount: commission,
      promoCode: promoCode || null,
      discountAmount: discountAmount || 0,
      assignedEmployeeId: null,
      suggestedDate: [],
      suggestedTimes: [],
      status: "unconfirmed",
      actionLogs: [],
    };

    if (isDepositAgreed) {
      createBooking(bookingData, {
        onSuccess: () => navigate("/requests"),
      });
    } else {
      console.log("Please agree to the deposit amount first.");
    }
  };

  return (
    <div className="booking-page bg-gray-900 min-h-screen p-4 text-white">
      <Link to={`/services/${id}`}>
        <button className="pill bg-blue-900 text-white my-4 px-4 py-2 rounded-full font-bold">
          Back
        </button>
      </Link>
      <h2 className="text-3xl font-semibold">Book Service</h2>
      <div className="flex">
        <p className="mt-4 text-white bg-green-600 p-2 text-lg mr-2 font-semibold">
          Service: {service.serviceName}
        </p>
        <p className="mt-4 text-white bg-purple-800 p-2 text-lg mr-2 font-semibold">
          Package: {selectedPackage.name}
        </p>
      </div>

      {/* Original and Discounted Price Display */}
      <div className="flex font-semibold">
        <p className="mt-4 text-gray-800 bg-orange-500 p-2 font-semibold text-lg mr-2">
          Original Price: K{fullPaymentAmount}
        </p>
        {discountAmount > 0 && (
          <p className="mt-4 text-gray-800 bg-green-500 p-2 font-semibold text-lg mr-2">
            Discounted Price: K{discountedTotal}
          </p>
        )}
        <p className="mt-4 text-gray-800 bg-yellow-400 font-semibold p-2 text-lg mr-2">
          Deposit Required: {selectedPackage.depositType === "amount"
            ? `K${deposit}`
            : `${deposit} ZMW (${selectedPackage.depositValue}%)`}
        </p>
      </div>

      <form onSubmit={handleBookingSubmit}>
        <div className="mt-4">
          <label>Promo Code:</label>
          <input
            type="text"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            className="w-full px-3 py-2 bg-gray-800 rounded-lg"
          />
          <button
            type="button"
            onClick={handlePromoCodeApply}
            className="bg-blue-500 text-white px-3 py-1 mt-2 rounded-lg"
          >
            Apply
          </button>
          {discountAmount > 0 && (
            <p className="mt-2 text-green-400">
              Discount applied: K{discountAmount} off!
            </p>
          )}
        </div>
        
        <div className="mt-4">
          <label>Date:</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="w-full px-3 py-2 bg-gray-800 rounded-lg"
          />
        </div>
        <div className="mt-4">
          <label>Time:</label>
          <input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            className="w-full px-3 py-2 bg-gray-800 rounded-lg"
          />
        </div>
        <div className="mt-4">
          <label>Notes:</label>
          <textarea
            value={customerNote}
            onChange={(e) => setCustomerNote(e.target.value)}
            className="w-full px-3 py-2 bg-gray-800 rounded-lg"
          ></textarea>
        </div>
        <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            checked={isDepositAgreed}
            onChange={(e) => setIsDepositAgreed(e.target.checked)}
            className="mr-2"
          />
          <label className="text-white">
            I agree to reserve this service for {selectedPackage.depositType === "amount"
              ? `K${deposit}`
              : `${deposit} ZMW (${selectedPackage.depositValue}%)`}
          </label>
        </div>
        <button
          type="submit"
          className="bg-green-500 text-white font-bold px-4 py-2 rounded-lg mt-4"
        >
          Confirm
        </button>
      </form>
      <div className="mt-20"></div>
    </div>
  );
};

export default BookNow;






