import React from 'react';
import { useAuthData } from '../context/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../services/firebase.config';
import { useNavigate } from 'react-router-dom';

const AccountSettings = () => {
  const { userData } = useAuthData();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="account-settings bg-gray-900 min-h-screen p-4 text-white">
      <h2 className="text-3xl font-semibold mb-4">Account Settings</h2>
      <div className="user-info mb-6">
        <p><strong>Name:</strong> {userData?.name}</p>
        <p><strong>Email:</strong> {userData?.email}</p>
        <p><strong>City:</strong> {userData?.city}</p>
        <p><strong>NRC:</strong> {userData?.nrc}</p>
        {/* Other user details here */}
      </div>
      <button
        onClick={handleSignOut}
        className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg"
      >
        Sign Out
      </button>
    </div>
  );
};

export default AccountSettings;
