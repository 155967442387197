import { useQuery } from 'react-query';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../services/firebase.config';

// Fetch Requests Data with Customer Details
const fetchRequestsWithCustomerDetails = async () => {
  const bookingsCollection = collection(db, 'AYS Lite Bookings');
  const snapshot = await getDocs(bookingsCollection);

  const requests = await Promise.all(
    snapshot.docs.map(async (docSnapshot) => {
      const requestData = docSnapshot.data();
      
      // Fetch the customer details using customerId
      const customerRef = doc(db, 'AYS Lite Users', requestData.customerId);
      const customerSnap = await getDoc(customerRef);

      return {
        ...requestData,
        requestId: docSnapshot.id,
        customer: customerSnap.exists() ? customerSnap.data() : null,
      };
    })
  );

  return requests;
};

// useRequestData Hook
export const useRequestData = () => {
  return useQuery('AYS Lite Requests', fetchRequestsWithCustomerDetails);
};










// import { useQuery } from 'react-query';
// import { collection, getDocs, getDoc } from 'firebase/firestore';
// import { db } from '../services/firebase.config';

// // Fetch Requests Data with Customer Names
// const fetchRequestsWithCustomerNames = async () => {
//   const requestsCollection = collection(db, 'AYS Lite Bookings'); // Assuming unconfirmed bookings are considered requests
//   const snapshot = await getDocs(requestsCollection);

//   const requests = await Promise.all(
//     snapshot.docs.map(async (doc) => {
//       const requestData = doc.data();

//       // Fetch the customer details using customerId
//       const customerRef = doc(db, 'AYS Customers', requestData.customerId);
//       const customerSnap = await getDoc(customerRef);

//       return {
//         ...requestData,
//         requestId: doc.id,
//         customerName: customerSnap.exists() ? customerSnap.data().name : 'Unknown',
//       };
//     })
//   );

//   return requests;
// };

// // useRequestData Hook
// export const useRequestData = () => {
//   return useQuery('AYS Lite Requests', fetchRequestsWithCustomerNames);
// };













// import { useQuery } from 'react-query';
// import { collection, getDocs, query, where } from 'firebase/firestore';
// import { db } from '../services/firebase.config';

// const fetchUnconfirmedBookings = async () => {
//   const bookingsCollection = collection(db, 'AYS Lite Bookings');
//   const q = query(bookingsCollection, where('isConfirmed', '==', false));
//   const querySnapshot = await getDocs(q);
//   return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
// };

// export const useRequestData = () => {
//   return useQuery('AYS Lite Unconfirmed Bookings', fetchUnconfirmedBookings);
// };
