import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useServiceData } from "../../hooks/useServices";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast, ToastContainer } from "react-toastify";

const PackageCard = ({ pkg }) => (
  <div className="bg-gray-800 p-4 rounded mb-4">
    <h4 className="text-xl font-semibold">{pkg.name}</h4>
    <p>Price: {pkg.price}</p>
    <p>Description: {pkg.description}</p>
  </div>
);

const ServiceDetails = () => {
  const { id } = useParams();
  const { data: services, isLoading, updateService } = useServiceData();
  const [service, setService] = useState(null);
  // const [image, setImage] = useState(null);
  // const [gallery, setGallery] = useState([]);
  const [packages, setPackages] = useState([]);
  const [editPackages, setEditPackages] = useState(false); // Toggle edit/view mode
  const storage = getStorage();
  const navigate = useNavigate();

  useEffect(() => {
    if (services) {
      const selectedService = services.find((s) => s.id === id);
      setService(selectedService);
      setPackages(selectedService?.packages || []); // Pre-populate packages if available
    }
  }, [services, id]);

    // Image upload handler
    const handleImageChange = async (e) => {
      try {
        const file = e.target.files[0];
        const storageRef = ref(storage, `services/${id}/image/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        const url = await getDownloadURL(snapshot.ref);
  
        console.log("Image uploaded successfully:", url);
        toast.success('Image uploaded successfully!');
  
        // Save the URL in your service object in Firestore
        const updatedService = { ...service, image: url };
        await updateService(updatedService);
        console.log("Service updated with image:", updatedService);
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error('Error uploading image.');
      }
    };
  
  // Handle package image upload
    const handlePackageImageUpload = async (index, file) => {
      try {
        const storageRef = ref(storage, `services/${id}/packages/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        const url = await getDownloadURL(snapshot.ref);

        // Update package image URL
        const updatedPackages = [...packages];
        updatedPackages[index].imageUrl = url;
        setPackages(updatedPackages);

        toast.success("Image uploaded successfully!");
      } catch (error) {
        console.error("Error uploading package image:", error);
        toast.error("Error uploading image.");
      }
    };
    // Gallery upload handler
    const handleGalleryChange = async (e) => {
      try {
        const files = Array.from(e.target.files);
        const uploadedUrls = [];
  
        for (const file of files) {
          const storageRef = ref(storage, `services/${id}/gallery/${file.name}`);
          const snapshot = await uploadBytes(storageRef, file);
          const url = await getDownloadURL(snapshot.ref);
          uploadedUrls.push(url);
        }
  
        console.log("Gallery images uploaded:", uploadedUrls);
        // toast.success('Gallery images uploaded successfully!');
  
        // Append the uploaded gallery URLs to the service object
        const updatedService = { ...service, gallery: uploadedUrls };
        await updateService(updatedService);
        console.log("Service updated with gallery:", updatedService);
      } catch (error) {
        console.error("Error uploading gallery:", error);
        // toast.error('Error uploading gallery images.');
      }
    };


  const handleAddPackage = () => {
    const newPackage = {
      name: "",
      price: "",
      description: "",
      affiliateCode: "",
      maxUsage: 0,
      depositType: "amount", // Default deposit type
      depositValue: 0, // Default deposit value
    };
    setPackages([...packages, newPackage]);
  };
  
  const handlePackageChange = (index, key, value) => {
    const updatedPackages = [...packages];
    updatedPackages[index][key] = value;
    setPackages(updatedPackages);
  };
  
  const handleRemovePackage = (index) => {
    const updatedPackages = [...packages];
    updatedPackages.splice(index, 1);
    setPackages(updatedPackages);
  };

  const handleSavePackages = async () => {
    try {
      const updatedService = { ...service, packages };
      await updateService(updatedService);
      console.log("Packages saved successfully:", updatedService);
      toast.success("Packages saved successfully!");
      setEditPackages(!editPackages);
      
    } catch (error) {
      console.error("Error saving packages:", error);
      toast.error("Packages saved successfully:!");
    }
  };

  // const handlePackageChange = (index, key, value) => {
  //   const updatedPackages = [...packages];
  //   updatedPackages[index][key] = value;
  //   setPackages(updatedPackages);
  // };

  const toggleEditPackages = () => {
    setEditPackages(!editPackages);
  };

  if (isLoading) return <p>Loading...</p>;
  if (!service) return <p>No service found</p>;

  return (
    <div className="bg-gray-900 text-white p-6 rounded-lg shadow-md">
      <ToastContainer/>
      
      <Link to={`/admin/services/`}>
        <button className="pill bg-blue-900 text-white my-4 px-4 py-2 rounded-full font-bold">
          Back
        </button>
      </Link>
      <h2 className="text-2xl font-bold mb-4">{service.serviceName} Details</h2>

      {/* Metadata */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Service Description</h3>
        <p className="bg-gray-800 p-4 rounded">{service.description}</p>
      </div>

      {/* Image Upload */}
      <div className="mb-6">
        <label className="block text-gray-300">Service Image:</label>
        <input type="file" onChange={handleImageChange} className="mt-2" />
      </div>

      {/* Gallery Upload */}
      <div className="mb-6">
        <label className="block text-gray-300">Gallery:</label>
        <input
          type="file"
          multiple
          onChange={handleGalleryChange}
          className="mt-2"
        />
      </div>

      {/* Toggle Edit Mode */}
      <button onClick={toggleEditPackages} className="text-blue-400 mb-4">
        {editPackages ? "View Packages" : "Edit Packages"}
      </button>

      {/* Package Management */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">Packages</h3>
    
        {editPackages ? (
          <>
            {packages.map((pkg, index) => (
              <div key={index} className="mb-4">
                <input
                  type="text"
                  placeholder="Package Name"
                  value={pkg.name}
                  onChange={(e) =>
                    handlePackageChange(index, "name", e.target.value)
                  }
                  className="block mb-2 w-full p-2 bg-gray-800 rounded"
                />
                 {/* Package Image */}
                <input
                  type="file"
                  onChange={(e) => handlePackageImageUpload(index, e.target.files[0])}
                  className="block mb-2 w-full p-2 bg-gray-800 rounded"
                />
                {pkg.imageUrl && (
                  <img src={pkg.imageUrl} alt={pkg.name} className="mt-2 rounded" />
                )}
                <input
                  type="number"
                  placeholder="Price"
                  value={pkg.price}
                  onChange={(e) =>
                    handlePackageChange(index, "price", e.target.value)
                  }
                  className="block mb-2 w-full p-2 bg-gray-800 rounded"
                />
                <textarea
                  placeholder="Description"
                  value={pkg.description}
                  onChange={(e) =>
                    handlePackageChange(index, "description", e.target.value)
                  }
                  className="block mb-2 w-full p-2 bg-gray-800 rounded"
                />
                  {/* Deposit Type (Amount or Percentage) */}
                  <label className="block mb-1 text-sm">Deposit Type:</label>
                  <select
                    value={pkg.depositType}
                    onChange={(e) =>
                      handlePackageChange(index, "depositType", e.target.value)
                    }
                    className="block mb-2 w-full p-2 bg-gray-800 rounded"
                  >
                    <option value="amount">Amount</option>
                    <option value="percentage">Percentage</option>
                  </select>

                  {/* Deposit Value */}
                  <input
                    type="number"
                    placeholder="Deposit Value"
                    value={pkg.depositValue}
                    onChange={(e) =>
                      handlePackageChange(index, "depositValue", e.target.value)
                    }
                    className="block mb-2 w-full p-2 bg-gray-800 rounded"
                  />
                {/* <input
                  type="text"
                  placeholder="Affiliate Code"
                  value={pkg.affiliateCode}
                  onChange={(e) =>
                    handlePackageChange(index, "affiliateCode", e.target.value)
                  }
                  className="block mb-2 w-full p-2 bg-gray-800 rounded"
                />
                <input
                  type="number"
                  placeholder="Max Usage"
                  value={pkg.maxUsage}
                  onChange={(e) =>
                    handlePackageChange(index, "maxUsage", e.target.value)
                  }
                  className="block mb-2 w-full p-2 bg-gray-800 rounded"
                /> */}
                <button
                  onClick={() => handleRemovePackage(index)}
                  className="text-red-400 mt-2"
                >
                  Remove Package
                </button>
              </div>
            ))}
            <button onClick={handleAddPackage} className="text-orange-400">
              Add Package
            </button>
            <button onClick={handleSavePackages} className="text-green-400 ml-4">
              Save Packages
            </button>
          </>
        ) : (
          <>
            {packages.map((pkg, index) => (
              <PackageCard key={index} pkg={pkg} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ServiceDetails;












