import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LoginForm from './components/Auth/LoginForm';
import AdminDashboard from './pages/AdminDashboard/AdminDashboard';
import { QueryClient, QueryClientProvider } from 'react-query';
import MainSpinner from './components/MainSpinner';
import { useAuthData } from './context/AuthContext';
import AdminSignUpForm from './components/Auth/AdminSignupForm';
import SignUpForm from './components/Auth/CustomerSignupForm';
import FrontendRoutes from './pages/ResponsiveNav/FrontendRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { userData, role, loading } = useAuthData(); 

  // if (loading) {
  //   return <MainSpinner />;
  // }

  // Wait for user data to load before rendering routes
  if (loading) {
    return <MainSpinner />;
  }

  // if (!userData) {
  //   return <LoginForm/>;
  // }


  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<MainSpinner />}>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<LoginForm />} />
        <Route path="/signup" element={<SignUpForm />} />
        {/* <Route path="/signup" element={<CustomerSignUpForm />} /> */}
        <Route path="/admin/signup" element={<AdminSignUpForm />} />

        {/* Admin and customer routing based on role */}
        {userData && role === 'admin' ? (
          <Route path="/admin/*" element={<AdminDashboard />} />
        ) : (
          <Route path="/*" element={<FrontendRoutes />} />
        )}

        {/* Default route logic */}
        <Route
          path="*"
          element={<Navigate to={userData ? (role === 'admin' ? '/admin' : '/home') : '/login'} />}
        />
      </Routes>
       
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
















// import React, { Suspense } from 'react';
// import { Route, Routes, Navigate } from 'react-router-dom';
// import LoginForm from './components/Auth/LoginForm';
// import AdminDashboard from './pages/AdminDashboard/AdminDashboard';
// import { QueryClient, QueryClientProvider } from 'react-query';
// import MainSpinner from './components/MainSpinner';
// import { useAuthData } from './context/AuthContext';
// import AdminSignUpForm from './components/Auth/AdminSignupForm';
// import CustomerSignUpForm from './components/Auth/CustomerSignupForm';
// import FrontendRoutes from './pages/ResponsiveNav/FrontendRoutes';

// function App() {
//   const { user, role, loading } = useAuthData();

//   if (loading) {
//     return <MainSpinner />;
//   }

//   const queryClient = new QueryClient();

//   return (
//     <QueryClientProvider client={queryClient}>
//       <Suspense fallback={<MainSpinner />}>
//         <Routes>
//           <Route path="/login" element={<LoginForm />} />
//           <Route path="/signup" element={<CustomerSignUpForm />} />
//           <Route path="/admin/signup" element={<AdminSignUpForm />} />

//           {user && role === 'admin' ? (
//             <Route path="/admin/*" element={<AdminDashboard />} />
//           ) : (
//             <Route path="/*" element={<FrontendRoutes/>} />
//           )}

//           {/* Default route */}
//           <Route path="/*" element={<Navigate to={user ? (role === 'admin' ? '/admin' : '/') : '/login'} />} />
//         </Routes>
//       </Suspense>
//     </QueryClientProvider>
//   );
// }

// export default App;
