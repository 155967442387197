// src/pages/AdminAffiliateDashboard.js

import React, { useState } from "react";
import AffiliateList from "../../components/Affiliates/AffiliateList";
import PromoCodeForm from "../../components/Affiliates/PromoCodeForm";
import AffiliateForm from "../../components/Affiliates/AffiliateForm";
import { useAffiliateCrud } from "../../hooks/useAffiliateCrud";
import MainSpinner from "../../components/MainSpinner";

const AdminAffiliateDashboard = () => {
  const {
    affiliates = [],
    isLoading,
    error,
    verifyAffiliate,
  } = useAffiliateCrud();
  const [selectedAffiliate, setSelectedAffiliate] = useState(null);
  const [showCreatForm, setShowCreatForm] = useState(false);

  const handleSelectAffiliate = (affiliate) => {
    setSelectedAffiliate(affiliate); // Set the selected affiliate for promo code creation
  };
  if (isLoading) {
    <MainSpinner />;
  }
  return (
    <div className="p-4">
      <h2 className="text-3xl font-bold mb-4">Admin Affiliate Dashboard</h2>

      <div>
        {/* Affiliate creation form */}
        <button
          className="bg-blue-600 rounded-xl  text-white px-4 py-2 mt-2"
          onClick={() => setShowCreatForm(true)}
        >
          Create New Affiliate
        </button>
      </div>
      {showCreatForm === true && (
        <>
          {" "}
          <button
            className="bg-gray-700 rounded-xl text-white px-4 py-2  mt-2 mb-2"
            onClick={() => setShowCreatForm(false)}
          >
            Cancel
          </button>
          <AffiliateForm />
        </>
      )}

      {/* Affiliate Promo Codes */}
      {selectedAffiliate && (
        <div className="mt-6">
          <div className="justify-between">
            <h3 className="text-xl font-semibold mb-2">
              Create Promo Code for {selectedAffiliate.name}
            </h3>
            <button
              className="bg-gray-700 text-white px-4 py-2 rounded mt-2"
              onClick={() => setSelectedAffiliate(null)}
            >
              Cancel
            </button>
          </div>

          <PromoCodeForm affiliateId={selectedAffiliate.id} />
        </div>
      )}
      {/*Affliate List  */}
      <AffiliateList
        affiliates={affiliates}
        onSelectAffiliate={handleSelectAffiliate}
      />
    </div>
  );
};

export default AdminAffiliateDashboard;
