import React from 'react';
import { Outlet } from 'react-router-dom';
import SideMenu from './SideMenu';

const DashboardLayout = () => {
  return (
    <div className="min-h-screen flex bg-gray-900 text-white">
      <SideMenu />
      <div className="flex-1 p-6">
        <Outlet /> {/* This will render the selected content */}
      </div>
    </div>
  );
};

export default DashboardLayout;
