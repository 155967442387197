import React from 'react';

const SPTableRow = ({ serviceProvider, availableCategories, availableAreas, onEdit, onDelete }) => {
  // Get area names
  const areaNames = serviceProvider.area
    .map((areaId) => availableAreas.find((area) => area.id === areaId)?.name)
    .filter(Boolean)
    .join(', '); // Combine area names into a comma-separated string

  // Get category names
  const categoryNames = serviceProvider.categories
    .map((categoryId) => availableCategories.find((category) => category.id === categoryId)?.name)
    .filter(Boolean)
    .join(', '); // Combine category names into a comma-separated string

  return (
    <tr className="hover:bg-gray-700">
      <td className="px-6 py-4">{serviceProvider.name}</td>
      {/* <td className="px-6 py-4">{serviceProvider.email}</td> */}
      <td className="px-6 py-4">{serviceProvider.phone}</td>
      <td className="px-6 py-4">{areaNames}</td>
      <td className="px-6 py-4">{categoryNames}</td>
      <td className="px-6 py-4">{serviceProvider.nrc}</td>
      <td className="px-6 py-4 space-x-2">
        <button
          className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
          onClick={onEdit}
        >
          Edit
        </button>
        <button
          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
          onClick={onDelete}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default SPTableRow;







// import React from 'react';

// const serviceProviderTableRow = ({ serviceProvider, onDelete }) => {
//   return (
//     <tr className="hover:bg-gray-700">
//       <td className="px-6 py-4">{serviceProvider.name}</td>
//       <td className="px-6 py-4">{serviceProvider.email}</td>
//       <td className="px-6 py-4">{serviceProvider.phone}</td>
//       <td className="px-6 py-4">{serviceProvider.area}</td>
//       <td className="px-6 py-4 space-x-2">
//         <button className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md">
//           Edit
//         </button>
//         <button
//           className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
//           onClick={onDelete}
//         >
//           Delete
//         </button>
//       </td>
//     </tr>
//   );
// };

// export default serviceProviderTableRow;
