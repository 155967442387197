import React, { useState, useEffect } from "react";
import { useCategoryData } from "../../hooks/userCategoryData";
import { useCityData } from "../../hooks/useCityData";
import { useAreaData } from "../../hooks/useAreaData";
import { useServiceData } from "../../hooks/useServices";

const ServiceForm = ({ onSubmit, serviceData, onClose }) => {
  const [serviceName, setServiceName] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [duration, setDuration] = useState("");
  const [city, setCity] = useState("");
  const [providerArea, setProviderArea] = useState([]); // Initialize as an empty array
  const [active, setActive] = useState(true);
  const [mobileService, setMobileService] = useState(false);
  const [recommended, setRecommended] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [commissionType, setCommissionType] = useState("Percentage");
  const [commissionRate, setCommissionRate] = useState(15.0);
  const [affiliateRate, setAffiliateRate] = useState(0.0);
  const [onPromo, setOnPromo] = useState(false); // Initialize promo state
  const [discountPercentage, setDiscountPercentage] = useState(0); // Initialize discountPercentage
  
  // hooks:
  const { createService, updateService } = useServiceData(); // Destructure the mutation methods

  const { categories = [] } = useCategoryData();
  const { cities = [] } = useCityData();
  const { areas = [] } = useAreaData();

  // Populate form if editing a service
  useEffect(() => {
    if (serviceData) {
      setServiceName(serviceData.serviceName || "");
      setCategory(serviceData.category || "");
      setSubcategory(serviceData.subcategory || "");
      setDescription(serviceData.description || "");
      setProviderArea(serviceData.providerArea || []);
      setDuration(serviceData.duration || "");
      setCity(serviceData.city || "");
      setPhone(serviceData.phone || "");
      setActive(serviceData.active || false);
      setMobileService(serviceData.mobileService || false);
      setRecommended(serviceData.recommended || false);
      setFeatured(serviceData.featured || false);
      setCommissionType(serviceData.commissionType || "Percentage");
      setCommissionRate(serviceData.commissionRate || 15.0);
      setAffiliateRate(serviceData.affiliateRate || 0.0);
      setOnPromo(serviceData.onPromo || false);
      setDiscountPercentage(serviceData.discountPercentage || 0);
    }
  }, [serviceData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newService = {
      serviceName,
      category,
      subcategory: subcategory || null,
      description,
      providerId: "AYS",
      providerName: "AYS Team",
      providerArea,
      phone,
      businessName: "AYS Zambia",
      serviceLocations: ["Lusaka"],
      city,
      active,
      commissionType,   // Stores either 'percentage' or 'amount'
      commissionRate,   // Rate used in calculations based on type
      activeAffiliate: affiliateRate > 0,
      affiliateRate,    // Default affiliate rate if any
      onPromo,
      discountPercentage,
      recommended,
      packages: [], 
      featured,
    };
    // const newService = {
    //   serviceName,
    //   category,
    //   subcategory: subcategory || null,
    //   description,
    //   providerId: "AYS", // Fixed provider ID
    //   providerName: "AYS Team", // Fixed provider name
    //   providerArea,
    //   phone,
    //   businessName: "AYS Zambia",
    //   serviceLocations: ["Lusaka"],
    //   city,
    //   active,
    //   commissionType,
    //   commissionRate,
    //   activeAffiliate: affiliateRate > 0,
    //   affiliateRate,
    //   onPromo,
    //   discountPercentage,
    //   recommended,
    //   packages: [], // Optional
    //   featured,
    // };

    if (serviceData && serviceData.id) {
      // If serviceData exists, we're editing the service
      updateService({ ...newService, id: serviceData.id });
    } else {
      // Otherwise, we're creating a new service
      createService(newService);
    }

    clearForm();
    if (onClose) onClose(); // Optionally close the form/modal after submission
  };

  const clearForm = () => {
    setServiceName("");
    setCategory("");
    setSubcategory("");
    setDescription("");
    setProviderArea([]);
    setPhone("");
    setDuration("");
    setCity("");
    setActive(true);
    setMobileService(false);
    setRecommended(false);
    setFeatured(false);
    setCommissionType("Percentage");
    setCommissionRate(15.0);
    setAffiliateRate(0.0);
    setOnPromo(false);
    setDiscountPercentage(0);
  };


  // Filter for subcategories based on the selected category
  const subcategoryOptions = categories.filter(
    (cat) => cat.parentCategoryId === category
  );
  // Filter areas based on the selected city
  const filteredAreas = areas.filter((area) => area.cityId === city);

  return (
    <form
    onSubmit={handleSubmit}
      className="bg-gray-800 p-6 rounded-lg shadow-md"
    >
      <h2 className="text-2xl font-bold text-white mb-6">
        {serviceData ? "Edit Service" : "Create Service"}
      </h2>
      {/* Service Name */}
      <div className="mb-4">
        <label className="block text-gray-400 text-sm font-bold mb-2">
          Service Name:
        </label>
        <input
          type="text"
          value={serviceName}
          onChange={(e) => setServiceName(e.target.value)}
          required
          className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
        />
      </div>
      {/* Category Select */}
      <div className="mb-4">
        <label className="block text-gray-400 text-sm font-bold mb-2">
          Category:
        </label>
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
          className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
        >
          <option value="">Select Category</option>
          {categories.map((cat) => (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          ))}
        </select>
      </div>

      {/* Subcategory Select (optional) */}
      {subcategoryOptions.length > 0 && (
        <div className="mb-4">
          <label className="block text-gray-400 text-sm font-bold mb-2">
            Subcategory (Optional):
          </label>
          <select
            value={subcategory}
            onChange={(e) => setSubcategory(e.target.value)}
            className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
          >
            <option value="">Select Subcategory</option>
            {subcategoryOptions.map((sub) => (
              <option key={sub.id} value={sub.id}>
                {sub.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Description */}
      <div className="mb-4">
        <label className="block text-gray-400 text-sm font-bold mb-2">
          Description:
        </label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
        />
      
      </div>
      {/* City */}
      <div className="mb-4">
        <label className="block text-gray-400 text-sm font-bold mb-2">
          City:
        </label>
        <select
          value={city}
          onChange={(e) => setCity(e.target.value)} // Set selected city
          required
          className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
        >
          <option value="">Select City</option>
          {cities.map((city) => (
            <option key={city.id} value={city.id}>
              {city.name}
            </option>
          ))}
        </select>
      </div>
      {/* Areas Multi-Select */}
      {city && filteredAreas.length > 0 && (
        <div className="mb-4">
          <label className="block text-gray-400 text-sm font-bold mb-2">
            Service Areas:
          </label>
          <select
            multiple
            value={providerArea} // Assume providerArea can be an array of selected areas
            onChange={(e) =>
              setProviderArea(
                Array.from(e.target.selectedOptions, (option) => option.value)
              )
            }
            className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
          >
            {filteredAreas.map((area) => (
              <option key={area.id} value={area.id}>
                {area.name}
              </option>
            ))}
          </select>
        </div>
      )}
      {/* Active */}
      <div className="mb-4 flex">
        <label className=" mr-1 text-gray-400 text-sm font-bold mb-2">
          Active:
        </label>
        <input
          type="checkbox"
          checked={active}
          onChange={(e) => setActive(e.target.checked)}
          className="h-5 w-5 mr-4 text-orange-500"
        />
         {/* Mobile Service */}
        <label className=" mr-1 text-gray-400 text-sm font-bold mb-2">
          Mobile Service:
        </label>
        <input
          type="checkbox"
          checked={mobileService}
          onChange={(e) => setMobileService(e.target.checked)}
          className="h-5 w-5 mr-4 text-orange-500"
        />
         <div>
          {/* Recommended */}
        <label className="mr-1 text-gray-400 text-sm font-bold mb-2">
          Recommended:
        </label>
        <input
          type="checkbox"
          checked={recommended}
          onChange={(e) => setRecommended(e.target.checked)}
          className="h-5 mr-4 w-5 text-orange-500"
        />
        </div>
        {/* Featured */}
        <div>
        <label className="mr-1 text-gray-400 text-sm font-bold mb-2">
          Featured:
        </label>
        <input
          type="checkbox"
          checked={featured}
          onChange={(e) => setFeatured(e.target.checked)}
          className="h-5 w-5 mr-4 text-orange-500"
        />
        </div>
      </div>
      {/* Commission Type */}
      <div className="mb-4">
        <label className="block text-gray-400 text-sm font-bold mb-2">
          Commission Type:
        </label>
        <select
          value={commissionType}
          onChange={(e) => setCommissionType(e.target.value)}
          required
          className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
        >
          <option value="Percentage">Percentage</option>
          <option value="Flat Rate">Flat Rate</option>
        </select>
      </div>

      {/* Commission Rate */}
      <div className="mb-4">
        <label className="block text-gray-400 text-sm font-bold mb-2">
          Commission Rate (%):
        </label>
        <input
          type="number"
          value={commissionRate}
          onChange={(e) => setCommissionRate(e.target.value)}
          required
          className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
        />
      </div>

      {/* Affiliate Rate */}
      <div className="mb-4">
        <label className="block text-gray-400 text-sm font-bold mb-2">
          Affiliate Rate (%):
        </label>
        <input
          type="number"
          value={affiliateRate}
          onChange={(e) => setAffiliateRate(e.target.value)}
          className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
        />
      </div>

      {/* On Promo */}
      <div className="mb-4">
       <label className="block text-gray-400 text-sm font-bold mb-2">
    On Promo:
       </label>
          <input
            type="checkbox"
            checked={onPromo}
            onChange={(e) => setOnPromo(e.target.checked)}
            className="h-5 w-5 text-orange-500"
          />
        </div>
        {/* Discount Percentage */}
        {onPromo && (
          <div className="mb-4">
            <label className="block text-gray-400 text-sm font-bold mb-2">
              Discount Percentage:
            </label>
            <input
              type="number"
              value={discountPercentage}
              onChange={(e) => setDiscountPercentage(e.target.value)}
              min="0"
              max="100"
              className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
            />
          </div>
        )}
      
      <div className="flex justify-between">
        <button
          type="submit"
          className="bg-orange-500 text-white px-6 py-2 rounded-lg shadow-md"
        >
          {serviceData ? "Update Service" : "Create Service"}
        </button>

        {onClose && (
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-500 text-white px-6 py-2 rounded-lg shadow-md"
          >
            Cancel
          </button>
        )}
      </div>
    </form>
  );
};

export default ServiceForm;


