import { useQuery, useMutation, useQueryClient } from 'react-query';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../services/firebase.config';

// Fetch promo codes
const fetchPromoCodes = async () => {
  const promoCodesQuery = collection(db, 'Promo Codes');
  const snapshot = await getDocs(promoCodesQuery);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};
// Function to fetch promo codes by code and service ID
const validatePromoCode = async (code, serviceId) => {
  const promoCodesQuery = query(
    collection(db, 'Promo Codes'),
    where('codeName', '==', code),
    where('serviceId', '==', serviceId)
  );

  const snapshot = await getDocs(promoCodesQuery);
  if (snapshot.empty) {
    return { isValid: false };
  }

  const promoCodeData = snapshot.docs[0].data();
  if (promoCodeData.usageCount >= promoCodeData.usageLimit) {
    return { isValid: false };
  }

  return { 
    isValid: true, 
    discount: promoCodeData.discount, 
    affiliateId: promoCodeData.affiliateId 
  };
};

// Fetch promo codes by affiliate ID
const fetchPromoCodesByAffiliate = async (affiliateId) => {
  const promoCodesQuery = query(
    collection(db, 'Promo Codes'),
    where('affiliateId', '==', affiliateId)
  );
  const snapshot = await getDocs(promoCodesQuery);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// usePromoCodeCrud hook
export const usePromoCodeCrud = () => {
  const queryClient = useQueryClient();

  const { data: promoCodes, isLoading, error } = useQuery('promoCodes', fetchPromoCodes);

  const createPromoCode = useMutation(
    async (newPromoCode) => {
      await addDoc(collection(db, 'Promo Codes'), newPromoCode);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('promoCodes');
      },
    }
  );

  // Function to get promo codes by affiliate
  const getPromoCodesByAffiliate = async (affiliateId) => {
    return fetchPromoCodesByAffiliate(affiliateId);
  };

  return {
    promoCodes,
    isLoading,
    error,
    createPromoCode,
    getPromoCodesByAffiliate,  // New function to get affiliate-specific promo codes
    validatePromoCode
  };
};












// // src/hooks/usePromoCodeCrud.js
// import { useQuery, useMutation, useQueryClient } from 'react-query';
// import { collection, addDoc, getDocs, updateDoc, doc } from 'firebase/firestore';
// import { db } from '../services/firebase.config';

// // Fetch promo codes
// const fetchPromoCodes = async () => {
//   const promoCodesQuery = collection(db, 'Promo Codes');
//   const snapshot = await getDocs(promoCodesQuery);
//   return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
// };

// // usePromoCodeCrud hook
// export const usePromoCodeCrud = () => {
//   const queryClient = useQueryClient();

//   const { data: promoCodes, isLoading, error } = useQuery('promoCodes', fetchPromoCodes);

//   const createPromoCode = useMutation(
//     async (newPromoCode) => {
//       await addDoc(collection(db, 'Promo Codes'), newPromoCode);
//     },
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries('promoCodes');
//       },
//     }
//   );

//   return {
//     promoCodes,
//     isLoading,
//     error,
//     createPromoCode,
//   };
// };
