import React, { useState, useEffect } from 'react';
import { useCityData } from '../../hooks/useCityData';

const CityForm = ({ cityData, onClose }) => {
  const [name, setName] = useState('');
  const [active, setActive] = useState(true);
  const { createCity, updateCity } = useCityData();

  useEffect(() => {
    if (cityData) {
      setName(cityData.name || '');
      setActive(cityData.active || false);
    }
  }, [cityData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newCity = {
      name,
      active,
    };

    if (cityData) {
      updateCity({ id: cityData.id, updatedCity: newCity });
    } else {
      createCity(newCity);
    }

    onClose();
  };

  return (
    <form onSubmit={handleSubmit} className="bg-gray-800 p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-white mb-6">
        {cityData ? 'Edit City' : 'Create City'}
      </h2>
      <div className="mb-4">
        <label className="block text-gray-400 text-sm font-bold mb-2">City Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
        />
      </div>
      <div className="mb-4 flex items-center">
        <input
          type="checkbox"
          checked={active}
          onChange={(e) => setActive(e.target.checked)}
          className="h-5 w-5 text-orange-500"
        />
        <label className="ml-2 text-gray-400 text-sm">Active</label>
      </div>
      <div className="flex space-x-4 mt-6">
        <button
          type="submit"
          className="bg-orange-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-orange-600 transition duration-300"
        >
          {cityData ? 'Update City' : 'Create City'}
        </button>
        <button
          type="button"
          className="bg-gray-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-gray-600 transition duration-300"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default CityForm;
