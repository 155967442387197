// src/components/Affiliates/PromoCodeForm.js

import React, { useState } from 'react';
import { useServiceData } from '../../hooks/useServices';
import { usePromoCodeCrud } from '../../hooks/usePromoCodeCrud';

const PromoCodeForm = ({ affiliateId }) => {
  const { createPromoCode } = usePromoCodeCrud();
  const { data: services } = useServiceData();
  const [codeName, setCodeName] = useState('');
  const [discount, setDiscount] = useState(0);
  const [usageLimit, setUsageLimit] = useState(10);
  const [serviceId, setServiceId] = useState('');
  const [affiliateRate, setAffiliateRate] = useState(''); // Affiliate rate

  const handleSubmit = (e) => {
    e.preventDefault();

    createPromoCode.mutate({
      codeName,
      discount,
      usageLimit,
      affiliateRate: affiliateRate || 5,
      affiliateId,
      serviceId,
    }, {
      onSuccess: () => {
        setCodeName('');
        setDiscount(0);
        setUsageLimit(10);
        setServiceId('');
      },
    });
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 bg-gray-800 rounded-lg shadow-lg">
      <h3 className="text-lg font-bold mb-4">Create Promo Code</h3>
      <input
        type="text"
        placeholder="Promo Code Name"
        value={codeName}
        onChange={(e) => setCodeName(e.target.value)}
        required
        className="w-full p-2 mb-2 rounded bg-gray-700 text-white"
      />
        <input
        type="number"
        placeholder="Affiliate Rate (%)"
        value={affiliateRate}
        onChange={(e) => setAffiliateRate(Number(e.target.value))}
      />
      <input
        type="number"
        placeholder="Discount (%)"
        value={discount}
        onChange={(e) => setDiscount(e.target.value)}
        required
        className="w-full p-2 mb-2 rounded bg-gray-700 text-white"
      />
      <input
        type="number"
        placeholder="Usage Limit"
        value={usageLimit}
        onChange={(e) => setUsageLimit(e.target.value)}
        required
        className="w-full p-2 mb-2 rounded bg-gray-700 text-white"
      />
      <select
        value={serviceId}
        onChange={(e) => setServiceId(e.target.value)}
        className="w-full p-2 mb-2 rounded bg-gray-700 text-white"
        required
      >
        <option value="">Select Service</option>
        {services?.map(service => (
          <option key={service.id} value={service.id}>{service.serviceName}</option>
        ))}
      </select>
      <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded mt-2">
        Create Promo Code
      </button>
    </form>
  );
};

export default PromoCodeForm;

