import React, { useState, useEffect } from 'react';
import { useCategoryData } from '../../hooks/userCategoryData';
import { useCityData } from '../../hooks/useCityData';
import { useAreaData } from '../../hooks/useAreaData';
import { useServiceProviderCrud } from '../../hooks/useServiceProdiverCrud';

const ServiceProviderForm = ({ serviceProvider, closeForm }) => {
  const { createServiceProvider, updateServiceProvider } = useServiceProviderCrud();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [area, setArea] = useState([]);
  const [city, setCity] = useState('');
  const [nrc, setNRC] = useState(serviceProvider?.nrc || '');
  const [categories, setCategories] = useState([]);

  // Fetch available categories, cities, and areas
  const { categories: availableCategories = [] } = useCategoryData();
  const { cities = [] } = useCityData();
  const { areas: availableAreas = [] } = useAreaData();

  // Populate form fields with ServiceProvider data if editing
  useEffect(() => {
    if (serviceProvider) {
      setName(serviceProvider.name);
      setEmail(serviceProvider.email);
      setPhone(serviceProvider.phone);
      setCity(serviceProvider.city);
      setArea(serviceProvider.area || []);
      setNRC(serviceProvider.nrc);
      setCategories(serviceProvider.categories || []);
    }
  }, [serviceProvider]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const serviceProviderData = { name, email, phone, area, city, categories, nrc };
    if (serviceProvider?.id) {
      // Update existing ServiceProvider
      updateServiceProvider(serviceProvider.id, serviceProviderData);
    } else {
      // Create new ServiceProvider
      createServiceProvider(serviceProviderData);
    }
    closeForm(); // Close form after submission
  };

  const handleAreaChange = (e) => {
    const selectedAreas = Array.from(e.target.selectedOptions, option => option.value);
    setArea(selectedAreas);
  };

  const handleCategoryChange = (e) => {
    const selectedCategories = Array.from(e.target.selectedOptions, option => option.value);
    setCategories(selectedCategories);
  };

  // Filter areas based on the selected city
  const filteredAreas = availableAreas.filter(area => area.cityId === city);

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-4 text-center text-white">
        {serviceProvider ? 'Edit ServiceProvider' : 'Create ServiceProvider'}
      </h2>
      <form onSubmit={handleSubmit}>
        {/* Name */}
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-3 py-2 border text-black rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            required
          />
        </div>

        {/* Email */}
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border text-black rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
            
          />
        </div>

        {/* Phone */}
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">Phone:</label>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-orange-500"
            required
          />
        </div>

        {/* City */}
        <div className="mb-4">
          <label className="block text-gray-400 text-sm font-bold mb-2">
            City:
          </label>
          <select
            value={city}
            onChange={(e) => setCity(e.target.value)} // Set selected city
            required
            className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
        </div>

        {/* Areas Multi-Select */}
        {city && filteredAreas.length > 0 && (
          <div className="mb-4">
            <label className="block text-gray-400 text-sm font-bold mb-2">
              Service Areas:
            </label>
            <select
              multiple
              value={area}
              onChange={handleAreaChange}
              className="w-full px-3 py-2 border border-gray-600 rounded-lg bg-gray-700 text-white"
            >
              {filteredAreas.map((area) => (
                <option key={area.id} value={area.id}>
                  {area.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Categories Multi-Select */}
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">Categories:</label>
          <select
            multiple
            value={categories}
            onChange={handleCategoryChange}
            className="w-full px-3 py-2 border rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-orange-500"
            required
          >
            {availableCategories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        {/* NRC */}
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2">NRC/ID:</label>
          <input
            type="text"
            value={nrc}
            onChange={(e) => setNRC(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-orange-500"
            required
          />
        </div>

        {/* Submit and Cancel buttons */}
        <div className="flex justify-between">
          <button
            type="submit"
            className="bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 transition duration-300"
          >
            {serviceProvider ? 'Update ServiceProvider' : 'Create ServiceProvider'}
          </button>
          <button
            type="button"
            onClick={closeForm}
            className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ServiceProviderForm;





























// import React, { useState, useEffect } from 'react';
// import { useServiceProviderCrud } from '../../hooks/useServiceProviders';

// const ServiceProviderForm = ({ ServiceProvider, closeForm }) => {
//       const { createServiceProvider, updateServiceProvider, isLoading, isError } = useServiceProviderCrud();
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [area, setArea] = useState('');
//     const [nrc, setNRC] = useState(ServiceProvider?.nrc || '');

//   // Populate form fields with ServiceProvider data if editing
//   useEffect(() => {
//     if (ServiceProvider) {
//       setName(ServiceProvider.name);
//       setEmail(ServiceProvider.email);
//       setPhone(ServiceProvider.phone);
//       setArea(ServiceProvider.area);
//       setNRC(ServiceProvider.nrc);
//     }
//   }, [ServiceProvider]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const ServiceProviderData = { name, email, phone, area, nrc };
//         if (ServiceProvider?.id) {
//                //   // Update existing ServiceProvider
//       console.log('Updating ServiceProvider:', ServiceProviderData);
//       updateServiceProvider(ServiceProvider.id, ServiceProviderData);  // Edit existing ServiceProvider
//     } else {
//             //   // Create new ServiceProvider
//       console.log('Creating new ServiceProvider:', ServiceProviderData);
//       createServiceProvider(ServiceProviderData);  // Create new ServiceProvider
//     }
    
//     // if (ServiceProvider) {
//     //   // Update existing ServiceProvider
//     //   console.log('Updating ServiceProvider:', ServiceProviderData);
//     // } else {
//     //   // Create new ServiceProvider
//     //   console.log('Creating new ServiceProvider:', ServiceProviderData);
//     // }
//     closeForm(); // Close form after submission
//   };

//   return (
//     <div className="bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-md mx-auto">
//       <h2 className="text-2xl font-bold mb-4 text-center text-white">
//         {ServiceProvider ? 'Edit ServiceProvider' : 'Create ServiceProvider'}
//       </h2>
//       <form onSubmit={handleSubmit}>
//         <div className="mb-4">
//           <label className="block text-white text-sm font-bold mb-2">Name:</label>
//           <input
//             type="text"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             className="w-full px-3 py-2 border text-black rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-white text-sm font-bold mb-2">Email:</label>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             className="w-full px-3 py-2 border text-black rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-white text-sm font-bold mb-2">Phone:</label>
//           <input
//             type="text"
//             value={phone}
//             onChange={(e) => setPhone(e.target.value)}
//             className="w-full px-3 py-2 border rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-orange-500"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-white text-sm font-bold mb-2">Area:</label>
//           <input
//             type="text"
//             value={area}
//             onChange={(e) => setArea(e.target.value)}
//             className="w-full px-3 py-2 border rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-orange-500"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-white text-sm font-bold mb-2">NRC/ID:</label>
//           <input
//             type="text"
//             value={nrc}
//             onChange={(e) => setArea(e.target.value)}
//             className="w-full px-3 py-2 border rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-orange-500"
//             required
//           />
//         </div>
//         <div className="flex justify-between">
//           <button
//             type="submit"
//             className="bg-orange-500 text-white py-2 px-4 rounded-md hover:bg-orange-600 transition duration-300"
//           >
//             {ServiceProvider ? 'Update ServiceProvider' : 'Create ServiceProvider'}
//           </button>
//           <button
//             type="button"
//             onClick={closeForm}
//             className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600"
//           >
//             Cancel
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default ServiceProviderForm;





