import React from 'react';
import { useCategoryData } from '../../hooks/userCategoryData';
import { useAreaData } from '../../hooks/useAreaData';
import SPTableRow from './SPTableRow';

const SPList = ({ serviceProviders, onEdit, onDelete }) => {
  const { categories: availableCategories = [] } = useCategoryData();
  const { areas: availableAreas = [] } = useAreaData();

  return (
    <table className="min-w-full text-left text-sm text-white">
      <thead className="bg-gray-800">
        <tr>
          <th className="px-6 py-3">Name</th>
          {/* <th className="px-6 py-3">Email</th> */}
          <th className="px-6 py-3">Phone</th>
          <th className="px-6 py-3">Area</th>
          <th className="px-6 py-3">Categories</th>
          <th className="px-6 py-3">NRC</th>
          <th className="px-6 py-3">Actions</th>
        </tr>
      </thead>
      <tbody>
        {serviceProviders.map((sp) => (
          <SPTableRow
            key={sp.id}
            serviceProvider={sp}
            availableCategories={availableCategories}
            availableAreas={availableAreas}
            onEdit={() => onEdit(sp)}
            onDelete={() => onDelete(sp.id)}
          />
        ))}
      </tbody>
    </table>
  );
};

export default SPList;












// import React from 'react';
// import EmployeeTableRow from './EmployeeTableRow';

// const EmployeeList = ({ employees, onEdit, onDelete }) => {
//   return (
//     <table className="min-w-full text-left text-sm text-white">
//       <thead className="bg-gray-800">
//         <tr>
//           <th className="px-6 py-3">Name</th>
//           <th className="px-6 py-3">Email</th>
//           <th className="px-6 py-3">Phone</th>
//           <th className="px-6 py-3">Area</th>
//           <th className="px-6 py-3">Categories</th>
//           <th className="px-6 py-3">NRC</th>
//           <th className="px-6 py-3">Actions</th>
//         </tr>
//       </thead>
//       <tbody>
//         {employees.map((employee) => (
//           <EmployeeTableRow
//             key={employee.id}
//             employee={employee}
//             onEdit={() => onEdit(employee)} // Pass employee data to onEdit handler
//             onDelete={() => onDelete(employee.id)}
//           />
//         ))}
//       </tbody>
//     </table>
//   );
// };

// export default EmployeeList;






// import React, { useState, useEffect } from 'react';
// import { useEmployeeCrud } from '../../hooks/useEmployees';
// import EmployeeTableRow from './EmployeeTableRow';
// import LoadingSpinner from '../LoadingSpinner';

// const EmployeeList = () => {
//   const { employees, deleteEmployee, isLoading, isError } = useEmployeeCrud();
  
//   if (isLoading) return <LoadingSpinner />;
//   if (isError) return <p className="text-red-500">Error loading employees.</p>;

//   return (
//     <div className="overflow-x-auto">
//       <table className="min-w-full bg-gray-800 text-white">
//         <thead>
//           <tr>
//             <th className="px-6 py-3">Name</th>
//             <th className="px-6 py-3">Email</th>
//             <th className="px-6 py-3">Phone</th>
//             <th className="px-6 py-3">Area</th>
//             <th className="px-6 py-3">Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {employees.map((employee) => (
//             <EmployeeTableRow
//               key={employee.id}
//               employee={employee}
//               onDelete={() => deleteEmployee(employee.id)}
//             />
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default EmployeeList;
