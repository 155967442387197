import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainSpinner from '../../components/MainSpinner';
import { useQuery, useMutation } from 'react-query';
import { collection, getDocs, query, where, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../services/firebase.config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { toast } from 'react-toastify';

const fetchConfirmedBookings = async () => {
  const bookingsCollection = collection(db, "AYS Lite Bookings");
  const q = query(bookingsCollection, where("isConfirmed", "==", true));
  const snapshot = await getDocs(q);

  const bookings = await Promise.all(
    snapshot.docs.map(async (bookingDoc) => {
      const bookingData = bookingDoc.data();
      const customerRef = doc(db, 'AYS Lite Users', bookingData.customerId);
      const customerSnap = await getDoc(customerRef);
      const customerData = customerSnap.exists() ? customerSnap.data() : {};

      return {
        ...bookingData,
        bookingId: bookingDoc.id,
        customerName: customerData.name || 'Unknown',
        customerArea: customerData.area || 'Unknown',
        customerPhone: customerData.phone || 'N/A',
      };
    })
  );

  return bookings;
};

const Bookings = () => {
  const { data: bookings, isLoading, error } = useQuery(
    ['AYS Lite ConfirmedBookings'],
    fetchConfirmedBookings
  );
  const navigate = useNavigate();
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [reviewImage, setReviewImage] = useState(null);
  const [editPhone, setEditPhone] = useState("");
  const [editNote, setEditNote] = useState("");

  const handleEndBooking = async (booking) => {
    try {
      let imageUrl = null;
      if (reviewImage) {
        const storageRef = ref(storage, `bookings/${booking.bookingId}/review-image/${reviewImage.name}`);
        const snapshot = await uploadBytes(storageRef, reviewImage);
        imageUrl = await getDownloadURL(snapshot.ref);
      }

      const bookingRef = doc(db, "AYS Lite Bookings", booking.bookingId);
      await updateDoc(bookingRef, {
        status: "Completed",
        rating,
        review,
        reviewImage: imageUrl,
      });
      toast.success("Booking completed successfully with review!");
    } catch (error) {
      console.error("Error ending booking:", error);
      toast.error("Error ending booking. Please try again.");
    }
  };

  const handleCancelBooking = async (booking) => {
    const bookingRef = doc(db, "AYS Lite Bookings", booking.bookingId);
    try {
      await updateDoc(bookingRef, {
        status: "Cancelled",
        cancelReason: "Customer requested cancellation",
      });
      toast.success("Booking cancelled successfully.");
    } catch (error) {
      console.error("Error cancelling booking:", error);
      toast.error("Error cancelling booking. Please try again.");
    }
  };

  const handleEditDetails = async (booking) => {
    const bookingRef = doc(db, "AYS Lite Bookings", booking.bookingId);
    try {
      await updateDoc(bookingRef, {
        customerPhone: editPhone,
        customerNote: editNote,
      });
      toast.success("Details updated successfully.");
    } catch (error) {
      console.error("Error updating details:", error);
      toast.error("Error updating details. Please try again.");
    }
  };

  const handleFileChange = (e) => setReviewImage(e.target.files[0]);

  if (isLoading) return <MainSpinner />;
  if (error) return <p>Something went wrong: {error.message}</p>;

  return (
    <div className="admin-bookings-container p-4">
      <h2 className="text-xl font-bold mb-4">Customer Bookings</h2>
      <ul className="booking-list">
        {bookings.map((booking) => (
          <li
            key={booking.bookingId}
            className="booking-item bg-gray-800 p-4 mb-4 rounded-lg shadow-lg"
          >
            <h3 className="text-lg font-semibold text-green-400">
              {booking.serviceName} - {booking.packageName}
            </h3>
            <p><strong>Customer:</strong> {booking.customerName} - {booking.customerPhone}</p>
            <p><strong>Area:</strong> {booking.customerArea}</p>
            <p><strong>Status:</strong> {booking.status}</p>
            <p><strong>Service Date:</strong> {booking.date} at {booking.time}</p>
            <p><strong>Employee Assigned:</strong> {booking.assignedEmployeeName || "Not assigned"}</p>
            <p><strong>Deposit Amount:</strong> K{booking.depositAmount} ZMW</p>
            <p><strong>Total Payment:</strong> K{booking.fullPaymentAmount} ZMW</p>

            {/* Edit Phone & Note */}
            <div>
            <h3 className="text-lg font-semibold text-green-400">
              Customer Message
            </h3>
              <p
                className="bg-gray-700 p-2 mt-2 rounded text-white w-full"
              >{booking.customerNote}</p>
              
            </div>

            {/* Cancel Booking */}
            <button
              onClick={() => handleCancelBooking(booking)}
              className="bg-red-500 text-white px-4 py-2 rounded mt-4"
            >
              Cancel Booking
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Bookings;




















// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import MainSpinner from '../../components/MainSpinner';
// import { useQuery } from 'react-query';
// import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
// import { db } from '../../services/firebase.config';

// const fetchConfirmedBookings = async () => {
//   const bookingsCollection = collection(db, "AYS Lite Bookings");
//   const q = query(bookingsCollection, where("isConfirmed", "==", true));
//   const snapshot = await getDocs(q);

//   const bookings = await Promise.all(
//     snapshot.docs.map(async (bookingDoc) => {
//       const bookingData = bookingDoc.data();

//       // Fetch customer data based on customerId
//       const customerRef = doc(db, 'AYS Lite Users', bookingData.customerId);
//       const customerSnap = await getDoc(customerRef);
//       const customerData = customerSnap.exists() ? customerSnap.data() : {};

//       return {
//         ...bookingData,
//         bookingId: bookingDoc.id,
//         customerName: customerData.name || 'Unknown',
//         customerArea: customerData.area || 'Unknown',
//         customerPhone: customerData.phone || 'N/A',
//       };
//     })
//   );

//   return bookings;
// };

// const Bookings = () => {
//   const { data: bookings, isLoading, error } = useQuery(
//     ['AYS Lite ConfirmedBookings'],
//     fetchConfirmedBookings
//   );
//   const navigate = useNavigate();

//   if (isLoading) return <MainSpinner />;
//   if (error) return <p>Something went wrong: {error.message}</p>;

//   return (
//     <div className="admin-bookings-container p-4">
//       <h2 className="text-xl font-bold mb-4">Customer Bookings</h2>
//       <ul className="booking-list">
//         {bookings.map((booking) => (
//           <li
//             key={booking.bookingId}
//             className="booking-item bg-gray-800 p-4 mb-4 rounded-lg shadow-lg cursor-pointer"
//             onClick={() => navigate(`/admin/bookings/${booking.bookingId}`)} // Go to booking details page
//           >
//             <h3 className="text-lg font-semibold text-green-400">
//               {booking.serviceName} - {booking.packageName}
//             </h3>
//             <p>
//               <strong>Customer:</strong> {booking.customerName} - {booking.customerPhone}
//             </p>
//             <p>
//               <strong>Area:</strong> {booking.customerArea}
//             </p>
//             <p>
//               <strong>Status:</strong> {booking.status}
//             </p>
//             <p>
//               <strong>Service Date:</strong> {booking.date} at {booking.time}
//             </p>
//             <p>
//               <strong>Employee Assigned:</strong> {booking.assignedEmployeeName || "Not assigned"}
//             </p>
//             <p>
//               <strong>Commission:</strong> K{booking.commissionAmount || 0} ZMW
//             </p>
//             <p>
//               <strong>Payment Status:</strong> 
//               {booking.isDepositPaid ? "Deposit Paid" : "Deposit Pending"} - 
//               {booking.isFullPaymentPaid ? " Full Payment Completed" : " Full Payment Pending"}
//             </p>
//             <p>
//               <strong>Deposit Amount:</strong> K{booking.depositAmount} ZMW
//             </p>
//             <p>
//               <strong>Total Payment:</strong> K{booking.fullPaymentAmount} ZMW
//             </p>
//             <p>
//               <strong>Suggested Reschedule:</strong>
//               {booking.suggestedDate.length > 0 
//                 ? booking.suggestedDate.map((date, index) => (
//                     <span key={index}>
//                       {date} at {booking.suggestedTimes[index] || "No time"}
//                     </span>
//                   ))
//                 : " No reschedule suggested"}
//             </p>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default Bookings;







