// src/components/CommissionTracker.js
import React from 'react';
import { useCommissionTracker } from '../../hooks/useCommissionTracker';
import MainSpinner from '../MainSpinner';


const CommissionTracker = ({ affiliateId }) => {
  const { commissions, isLoading, error } = useCommissionTracker(affiliateId);

  if (isLoading) return <MainSpinner />;
  if (error) return <p>Error loading commissions: {error.message}</p>;

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Commissions</h2>
      <ul>
        {commissions.map((commission) => (
          <li key={commission.id} className="bg-gray-800 p-4 mb-4 rounded-lg shadow-lg">
            <p><strong>Promo Code:</strong> {commission.codeName}</p>
            <p><strong>Commission Earned:</strong> K{commission.amount} ZMW</p>
            <p><strong>Date:</strong> {new Date(commission.date).toLocaleDateString()}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CommissionTracker;
