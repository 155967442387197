// src/components/Admin/AffiliateForm.js

import React, { useState } from 'react';
import { useUserCreation } from '../../hooks/useUserCreation';
import { useAuth } from '../../hooks/useAuth';

const AffiliateForm = () => {
  const { mutate: createUserInFirestore } = useUserCreation();
  const { signupMutation } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const handleAffiliateCreate = (e) => {
    e.preventDefault();

    signupMutation.mutate(
      { email, password },
      {
        onSuccess: (userCredential) => {
          const uid = userCredential.user.uid;
          const affiliateData = {
            email,
            role: 'affiliate',
            name,
            phone,
            verified: false,
            status: 'active',
            nrc:"",
            city:"",
            gender:"",
            area:"",
          };
          createUserInFirestore({ uid, userData: affiliateData });
          setEmail('');
          setPassword('');
          setName('');
          setPhone('');
        },
      }
    );
  };

  return (
    <div className="p-4 bg-gray-800 rounded-lg shadow-lg">
      <h3 className="text-lg font-bold mb-4">Create Affiliate</h3>
      <form onSubmit={handleAffiliateCreate}>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="w-full p-2 mb-2 rounded bg-gray-700 text-white"
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full p-2 mb-2 rounded bg-gray-700 text-white"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="w-full p-2 mb-2 rounded bg-gray-700 text-white"
        />
        <input
          type="tel"
          placeholder="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
          className="w-full p-2 mb-2 rounded bg-gray-700 text-white"
        />
        <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded mt-2">
          Create Affiliate
        </button>
      </form>
    </div>
  );
};

export default AffiliateForm;
