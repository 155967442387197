import React from 'react';
import { Link,} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { FaHome, FaBook, FaTasks, FaUser } from 'react-icons/fa';

const NavigationTabs = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  if (isMobile) {
    return (
      // <>
      // <Outlet/>
      <div className="fixed bottom-0 left-0 w-full bg-gray-800 p-2 flex justify-around text-white">
        <Link to="/" className="flex flex-col items-center">
          <FaHome size={24} /> Home
        </Link>
        <Link to="/my-bookings" className="flex flex-col items-center">
          <FaBook size={24} /> Bookings
        </Link>
        <Link to="/requests" className="flex flex-col items-center">
          <FaTasks size={24} /> Requests
        </Link>
        <Link to="/affiliates" className="flex flex-col items-center">
          <FaTasks size={24} /> Affiliates
        </Link>
        <Link to="/account" className="flex flex-col items-center">
          <FaUser size={24} /> Profile
        </Link>
      </div>
      // </>
    );
  }

  // Larger device links in the app bar
  return (
    <nav className="bg-gray-800 p-4 text-white">
      <div className="container mx-auto flex justify-end">
        <Link to="/" className="mx-4">Home</Link>
        <Link to="/my-bookings" className="mx-4">Bookings</Link>
        <Link to="/requests" className="mx-4">Requests</Link>
        
        <Link to="/affiliates" className="flex flex-col items-center">
         Affiliates
        </Link>
        <Link to="/account" className="mx-4">Profile</Link>
      </div>
    </nav>
  );
};

export default NavigationTabs;
