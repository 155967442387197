import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRequestData } from '../../hooks/useRequests';
import MainSpinner from '../../components/MainSpinner';

const AdminRequestDetails = () => {
  const { requestId } = useParams(); // Fetch the requestId from the URL
  const { data: requests, isLoading } = useRequestData();
  const [adminNote, setAdminNote] = useState('');
  const [employeeId, setEmployeeId] = useState(''); // To assign a service provider

  const request = requests?.find((r) => r.requestId === requestId);

  if (isLoading) return <MainSpinner />;
  if (!request) return <p>Request not found</p>;

  const handleAssign = () => {
    // Logic to assign an employee (service provider)
  };

  const handleAccept = () => {
    // Logic to accept the request
  };

  const handleDecline = () => {
    // Logic to decline the request
  };

  const handleReschedule = () => {
    // Logic to reschedule the request
  };

  return (
    <div className="request-details bg-gray-900 min-h-screen p-4 text-white">
      <h2 className="text-3xl font-semibold mb-4">{request.serviceName}</h2>
      <p className="text-lg mb-6">Customer: {request.customerName}</p>
      <p className="text-lg mb-6">Status: {request.status}</p>
      <textarea
        className="w-full bg-gray-800 p-2 mb-4"
        placeholder="Admin Notes"
        value={adminNote}
        onChange={(e) => setAdminNote(e.target.value)}
      />
      <div className="actions">
        <button onClick={handleAssign} className="btn bg-green-500">Assign Employee</button>
        <button onClick={handleAccept} className="btn bg-blue-500">Accept</button>
        <button onClick={handleDecline} className="btn bg-red-500">Decline</button>
        <button onClick={handleReschedule} className="btn bg-yellow-500">Reschedule</button>
      </div>
    </div>
  );
};

export default AdminRequestDetails;
