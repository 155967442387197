import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../services/firebase.config'; // Assuming Firebase is configured here
import MainSpinner from '../../components/MainSpinner';

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch customers from 'AYS Lite Users' collection where role is 'customer'
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const usersCollection = collection(db, 'AYS Lite Users');
        const q = query(usersCollection, where('role', '==', 'customer'));
        const querySnapshot = await getDocs(q);
        const customerList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCustomers(customerList);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchCustomers();
  }, []);

  if (loading) return <MainSpinner />;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h2 className="text-3xl font-bold mb-4">Customers</h2>
      <ul>
        {customers.length === 0 ? (
          <p>No customers found.</p>
        ) : (
          customers.map(customer => (
            <li key={customer.id} className="bg-gray-800 p-4 mb-4 rounded-lg">
              <p><strong>Name:</strong> {customer.name}</p>
              <p><strong>Email:</strong> {customer.email}</p>
              <p><strong>Phone:</strong> {customer.phone}</p>
              <p><strong>City:</strong> {customer.city}</p>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default Customers;
